.login {
  /* background: url(/img/bg-image.png) no-repeat; */
  background-size: cover;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
}

#logo {
  height: 41px;
  width: 250px;
  display: block;
  margin: 70px auto 90px;
}

#login-header {
  background: none;
  margin: 0 auto;
  border: none;
}

#login-card {
  height: auto;
  opacity: 0.9;
  border-radius: 8px;
  box-shadow: none;
  background-color: #FFFFFF;
}

.login-card-body {
  margin: -15px 0 10px;
}

.login-col {
  width: 540px;
  margin: 0 auto;
}

.sign-in.btn {
  width: 185px;
  border-radius: 50px !important;
  background: linear-gradient(90deg, rgb(0, 146, 189), rgb(0, 95, 142));
  box-shadow: none;
  color: #fff;
}


.change-password {
  width: 240px;
}

.login-form {
  width: 80%;
  margin: 0 auto;
}

#login-welcome {
  text-align: center;
  margin: 0;
  padding: 0;
}

.login-welcome-ndash {
  font-size: 12px;
  margin: 0 8px;
}

.login-welcome-text {
  color: #505050;
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: 800;
}

.login-welcome-name {
  text-align: center;
  color: #505050;
  font-family: "Nunito Sans";
  font-size: 17px;
}


@media (max-width: 576px) {
  .login-welcome-text {
    font-size: 18px;
    font-weight: 800;
  }

  .login-col {
    width: 100%;
  }

  .login-welcome-name {
    font-size: 15px;
  }
}

.alert-danger-box {
  border: none !important;
  padding: 10px 5px 1px !important;
  margin: 0 auto !important;
  width: 80%;
}

.forgot-password {
  color: #0092BD;
}
